var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"re_wrap"},[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("@/assets/icon/DL_0005_fanhui.png")},on:{"click":_vm.handleClose}}),_c('p',{staticClass:"versions"},[_vm._v("Đăng ký")])]),_c('div',{staticClass:"cont"},[_vm._l((_vm.formList),function(item,index){return _c('div',{key:index,staticClass:"form",style:({
        'justify-content': item.key === 'phone' ? '' : 'space-between',
      })},[(item.key === 'phone' && _vm.verConfig.areaCode)?_c('span',{staticStyle:{"padding-left":"15px","padding-right":"12px","border-right":"1px solid #999"}},[_vm._v("+"+_vm._s(_vm.verConfig.areaCode))]):_vm._e(),((item.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[item.key]),expression:"form[item.key]"}],attrs:{"placeholder":item.label,"maxlength":item.length,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form[item.key])?_vm._i(_vm.form[item.key],null)>-1:(_vm.form[item.key])},on:{"change":function($event){var $$a=_vm.form[item.key],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, item.key, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, item.key, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, item.key, $$c)}}}}):((item.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[item.key]),expression:"form[item.key]"}],attrs:{"placeholder":item.label,"maxlength":item.length,"type":"radio"},domProps:{"checked":_vm._q(_vm.form[item.key],null)},on:{"change":function($event){return _vm.$set(_vm.form, item.key, null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[item.key]),expression:"form[item.key]"}],attrs:{"placeholder":item.label,"maxlength":item.length,"type":item.type},domProps:{"value":(_vm.form[item.key])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, item.key, $event.target.value)}}}),(item.key === 'code' && !_vm.isSendCodeClick)?_c('button',[_vm._v(" "+_vm._s(_vm.auth_time)+" ")]):_vm._e(),(
          (item.key === 'password' || item.key === 'againPassword') &&
          item.flag === true
        )?_c('img',{attrs:{"src":require("@/assets/icon/Lark20220424-175950.png")},on:{"click":function($event){return _vm.handleLookPass(item)}}}):_vm._e(),(
          (item.key === 'password' || item.key === 'againPassword') &&
          item.flag === false
        )?_c('img',{attrs:{"src":require("@/assets/icon/Lark20220424-180000.png")},on:{"click":function($event){return _vm.handleLookPass(item)}}}):_vm._e()])}),_c('div',{staticClass:"btn-box"},[_c('button',{on:{"click":_vm.handleConfirm}},[_vm._v("Nộp")]),_c('button',{on:{"click":_vm.handleClose}},[_vm._v("Đã có tài khoản, đăng nhập ngay")])])],2),_c('LoadingRE',{attrs:{"show":_vm.LoadingShow}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }