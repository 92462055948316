<template>
  <div class="my-container">
    <template v-if="!verConfig.useNewLogin">
      <!-- 导航栏 -->
      <nav-bar :title="registe" :backColor="'#ff0000'"></nav-bar>
      <!-- 注册表单 -->
      <div class="kong"></div>
      <div class="new-form">
        <div class="form-item" v-for="(item, index) in formList" :key="index">
          <span>
            <span v-if="item.required" style="color: #ff0000">* </span
            >{{ item.label }}
          </span>
          <input
            :type="item.type"
            :placeholder="item.placeholder"
            v-model="user[item.key]"
          />
          <p class="num-absolute" v-if="!index">+84</p>
          <template v-if="item.key === 'code1'">
            <van-count-down
              :time="1000 * 60"
              format="nhận lại ss thứ hai"
              v-if="isCountDownShow"
              @finish="isCountDownShow = false"
            />
            <p v-else @click="onSendSms">{{ registeinfo }}</p>
          </template>
        </div>
        <div class="checkbox">
          <van-checkbox
            v-model="checked"
            shape="square"
            checked-color="#DC1323"
            icon-size="20px"
          />
          <p>
            tôi chấp nhận<span @click="$router.push('agreement')"
              >&lt;&lt;Thỏa thuận người dùng và Chính sách quyền riêng
              tư&gt;&gt;</span
            >
          </p>
        </div>
        <div class="btn-box">
          <button @click="$router.back()">quay lại đăng nhập</button>
          <button @click="onSubmit">gửi đăng ký</button>
        </div>
      </div>

      <!-- <div class="registe">
      <div class="goLogin">
        <span @click="$router.back()">quay lại đăng nhập</span>
      </div>
    </div> -->
    </template>
    <NewRegiste v-else />
  </div>
</template>

<script>
// 导入导航栏组件
import Navbar from "@/components/Navbar.vue";
import "../my.less";
import { sendSms, login } from "@/api/use.js";
import NewRegiste from "@/views/login/components/newRegiste.vue";
// import request from '@/utils/request'

export default {
  name: "",
  components: {
    "nav-bar": Navbar,
    NewRegiste,
  },
  data() {
    return {
      registe: "thành viên đã đăng ký",
      user: {
        mobile: "",
        code: "112233",
        password: "",
        fromid: "",
        confirmPwd: "",
      },
      checked: true,
      //  点击信息的提示
      registeinfo: "กำลังรับรหัสยืนยัน",
      // 控制是否显示倒计时
      isCountDownShow: false,
      formList: [
        {
          label: "số điện thoại",
          required: true,
          type: "number",
          placeholder: "Xin vui lòng điền số điện thoại của bạn.!",
          key: "mobile",
        },
        // {
        //   label: 'mã mời',
        //   type: 'number',
        //   placeholder: 'Vui lòng nhập mã mời',
        //   key: 'code',
        // },
        {
          label: "Mật khẩu",
          type: "password",
          required: true,
          placeholder: "Xin hãy điền mật khẩu.",
          key: "password",
        },
        {
          label: "Xác nhận mật khẩu",
          type: "password",
          required: true,
          placeholder: "Vui lòng nhập lại mật khẩu.",
          key: "confirmPwd",
        },
        {
          label: "Mã giới thiệu",
          type: "text",
          required: false,
          placeholder: "Vui lòng nhập mã giới thiệu",
          key: "fromid",
        },
      ],
    };
  },
  mounted() {},
  created() {
    if (this.$route.query.id) {
      localStorage.setItem("fromid", this.$route.query.id);
      this.user.fromid = this.$route.query.id;
    }
  },
  methods: {
    // 1、点击获取短信事件
    async onSendSms() {
      // 调用发送验证码请求的异步请求
      if (this.user.mobile) {
        // 如果手机号验证成功，让倒计时显示
        this.isCountDownShow = true;
        try {
          const { data } = await sendSms({
            userphone: this.user.mobile,
            must: 1,
          });
          data.ret === 1
            ? this.$toast("Mã xác minh đã được nhận.")
            : this.$toast(
                "Đã gửi thành công, chỉ có thể gửi một lần trong vòng 60 giây."
              );
        } catch (error) {
          this.isCountDownShow = false;
        }
      } else this.$toast("Xin vui lòng nhập một số điện thoại hợp lệ.");
    },
    // 2、定义注册的事件
    async onSubmit(e) {
      // console.log(e)
      e.stopPropagation();
      if (this.checked) {
        let flag = true;
        for (let i = 0, len = this.formList.length; i < len; i++) {
          const item = this.formList[i];
          if (item.required) {
            if (!this.user[item.key]) {
              flag = false;
              return this.$toast(item.msg || item.placeholder);
              break;
            }
          }
        }
        if (
          !!this.formList.filter((item) => item.key === "confirmPwd").length &&
          this.user.confirmPwd !== this.user.password
        ) {
          return this.$toast("Hai mục nhập mật khẩu không nhất quán!");
        }
        const parame = {
          userphone: this.user.mobile,
          code: this.user.code,
          userpass: this.user.password,
          ke: true,
          fromid: this.user.fromid,
        };
        if (this.$route.query.id || localStorage.getItem("fromid")) {
          parame.fromid =
            this.$route.query.id || localStorage.getItem("fromid");
        }
        const { data } = await login({
          ...parame,
        });
        if (data.ret === 1) {
          this.$toast("Xin chúc mừng, đăng ký của bạn đã thành công.");
          this.onlogin();
          localStorage.removeItem("fromid");
        } else {
          console.log(1);
          this.$toast(data.msg);
        }

        // if (this.user.mobile || this.user.password || this.user.code) {
        //   const fromidIndex = this.formList.findIndex(item => item.key == 'fromid')
        //   if (fromidIndex !== -1) {
        //     if (this.formList[fromidIndex].required) {
        //       if (!this.user.fromid) {
        //         return this.$toast('Please enter the invitation code!')
        //       }
        //     }
        //   }
        //   if (
        //     this.user.confirmPwd === this.user.password ||
        //     !this.formList.filter((item) => item.key === 'confirmPwd').length
        //   ) {
        //     const parame = {
        //       userphone: this.user.mobile,
        //       code: this.user.code,
        //       userpass: this.user.password,
        //       ke: true,
        //       fromid: this.user.fromid
        //     }
        //     if (this.$route.query.id || localStorage.getItem('fromid')) {
        //       parame.fromid =
        //         this.$route.query.id || localStorage.getItem('fromid')
        //     }
        //     const { data } = await login({
        //       ...parame
        //     })
        //     if (data.ret === 1) {
        //       this.$toast('Xin chúc mừng, đăng ký của bạn đã thành công.')
        //       this.onlogin()
        //       localStorage.removeItem('fromid')
        //     } else {
        //       this.$toast(data.msg)
        //     }
        //   } else {
        //     this.$toast('Hai mục nhập mật khẩu không nhất quán!')
        //   }
        // } else this.$toast('Xin vui lòng nhập một số điện thoại hợp lệ.')
      } else this.$toast("bạn phải đồng ý");
    },

    // 3、注册成功直接登录
    async onlogin() {
      try {
        const {
          data: { ret, data },
        } = await login({
          userphone: this.user.mobile,
          userpass: this.user.password,
        });
        if (ret === 1) {
          this.$store.commit("setUser", data.token);
          this.$toast.success("đăng nhập thành công");
          localStorage.removeItem("fromid");
          this.$router.push("/");
        } else {
          this.$toast.fail(data.msg);
        }
      } catch (e) {}
    },
  },
};
</script>

<style lang="less" scoped>
@toast-position-bottom-distance:10% !;
.goLogin {
  margin-top: 30px;
  padding-right: 30px;
  display: flex;
  justify-content: flex-end;
  span {
    font-size: 26px;
  }
}
.registe {
  .van-field {
    margin-top: 18px;
  }
  .send-sms-btn {
    width: 152px;
    height: 46px;
    line-height: 46px;
    background-color: #fff;
    font-size: 22px;
    color: rgb(0, 76, 254);
    border: none;
  }
}

.checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 50px 0 18px 50px;
  .van-checkbox {
    margin-right: 25px;
  }
  > p {
    width: calc(100% - 20px);
    font-size: 30px;
    color: #b2b2b2;
    > span {
      color: #dc1323;
    }
  }
}

.registe-btn {
  background: #ff0000;
  border: none;
}
.registe-info {
  position: relative;
}
.otc-btn {
  position: absolute;
  top: 22px;
  right: 30px;
  background-color: #fff;
  padding-left: 10px;
  color: rgb(0, 76, 254);
  font-size: 35px;
  font-family: "PingFang-Medium";
}
.kong {
  height: 100px;
}
.new-form {
  width: 100%;
  height: calc(100% - 100px);
  background-color: #fff;
  .van-count-down {
    font-size: 30px;
  }
  > .form-item {
    width: 100%;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    flex-direction: column;
    padding: 30px;
    box-sizing: border-box;
    position: relative;
    > span {
      font-size: 36px;
      color: #323233;
      margin-bottom: 15px;
    }
    > input {
      font-size: 36px;
      color: #323233;
      border: 0;
    }
    p {
      font-size: 30px;
      color: #555555;
    }
  }
  > .form-item:nth-child(1) {
    padding-top: 50px;
    > input {
      padding-left: 70px;
    }
    > .num-absolute {
      position: absolute;
      font-size: 36px;
      color: #323233;
      bottom: 32px;
    }
  }
  > .btn-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    > button {
      width: 40%;
      height: 100px;
      border-radius: 6px;
      border: 0;
      font-size: 32px;
      color: #fff;
      background-color: #3eb4ff;
    }
    > button:nth-last-child(1) {
      background-color: #dc1323;
      margin-left: 50px;
    }
  }
}
</style>
